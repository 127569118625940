<template>
  <div id="header">
    <div id="logo" style="text-align:left; width:20%; height:100%;">
        <img src="../../assets/logos/LogiForm_logo.png" style="width:80%; height:70%;" />
    </div>
    <div id="middle" style="width:60%; height:100%;">
    </div>
    <div id="userLogIn" style="text-align:right; width:20%; height:100%; padding-right:3%; padding-top:1%;">
      {{this.$store.getters.getUserName}} 님 환영합니다
      <v-icon @click="dialogLogout=true" style="background-color:#a0c0ff">
        mdi-logout
      </v-icon>
    </div>

    <v-dialog
      v-model="dialogLogout"
      max-width="400"
      content-class="logout_dialog"
    >
      <v-card>
        <v-card-title class="text-h5" style="font-weight: bold;">
          로그아웃
        </v-card-title>
        <v-card-text class="text-h7" style="font-weight: bold;">
          로그인 페이지로 돌아가시겠습니까?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            style="width:40%; background-color: rgb(103, 173, 219); color:white;"
            @click="logout"
          >
            로그아웃
          </v-btn>
          <v-btn
            style="width:40%; background-color: rgb(103, 173, 219); color:white;"
            @click="dialogLogout = false"
          >
            취소
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
 
<script>

export default {
  name: 'Header',
  components: {
    
  },
  data() {
    return {
      dialogLogout:false
    };
  },

  methods : {
    logout() {
      this.dialogLogout = false
      this.$store.commit('clearUserInfo')
      this.$router.replace('login')
    }
  }

};
</script>
