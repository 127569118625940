<template>
  <div id="leftMenu">
    <v-list
      nav
      dense
      style="background:#e7dcdc;"
    >
      <v-list-item
        v-for="(menu, i) in cards"
        :key="i"
        link
        @mousedown="goToMenu(menu.code)"
        style="border:1px solid; "
        :class="{ 'active': menu.isActive, 'in-active': !menu.isActive }"
      >
        <img :src="menu.icon" style="width:20%;" />
        <v-list-item-content >
          <v-list-item-title style="margin:10%; font-size:15px;" :class="{ 'active2': menu.isActive, 'in-active2': !menu.isActive }">{{menu.name}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>  
</template>

<script>
export default {
  name: 'LeftMenu',
  data () {
    return {
      drawer:true,

      cards:  [
        { code:'STANDARD', name:'기준정보', link: 'components/views/StandardView', icon:require('@/assets/icons/manual_location.png'), isActive: true },
        { code:'LOCATIONMAN', name:'위치관리', link: 'components/views/LocationMan', icon:require('@/assets/icons/warehouse.png'), isActive: true },
        { code:'WEARING', name:'입고', link: 'components/views/Wearing', icon:require('@/assets/icons/warehouse.png'), isActive: true },
        { code:'RELEASE', name:'출고', link: 'components/views/Release', icon:require('@/assets/icons/shipped.png'), isActive: true },
        { code:'INOUTLIST', name:'입출고내역', link: 'components/views/InOutList', icon:require('@/assets/icons/search_item.png'), isActive: true },
        { code:'STOCKMOVE', name:'재고위치조정', link: 'components/views/StockMove', icon:require('@/assets/icons/search_location.png'), isActive: true },
        { code:'STOCKMGMT', name:'재고현황', link: 'components/views/StockMgmt', icon:require('@/assets/icons/search_item.png'), isActive: true },
        { code:'USERMGMT', name:'사용자관리', link: 'components/views/UserMgmt', icon:require('@/assets/icons/search_rate.png'), isActive: true },
      ],
    }
  },
  mount(){

  },

  watch: {
    $route: {
      handler(route) {
        this.cards.forEach(card => {
          if (!route.name) {
            if (card.code === 'STANDARD')
              card.isActive = true
            else
              card.isActive = false
          } else {
            if (card.code === route.name)
              card.isActive = true
            else
              card.isActive = false
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
  
  methods: {
    goToMenu(menu) {
      console.log(menu);
      this.$router.push(menu).catch(()=>{
         location.reload();
      });
    },    

    setFullScreen() {
        const element = document.documentElement;

            element.requestFullscreen();
    },

  },

}
</script>

<style>
.active {
  background: linear-gradient( #27ade3,#0c3d6e);
  background-color0: #1976d2;
  background-color1: rgb(041 100,200);
}

.in-active {
  background: linear-gradient(rgb(241 202 178), rgb(211 139 51));
}

.active2 {
  color:white;
}

.in-active2 {
  color:black;
}

</style>