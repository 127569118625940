import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import vuetify from './plugins/vuetify'
import router from './components/router/index'
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(VueRouter)
Vue.use(Vuex);
Vue.config.productionTip = false

// Vue.prototype.$vBACKEND_URL = 'https://web.msysict.co.kr'
// Vue.prototype.$vBACKEND_URL = 'http://172.30.1.35:7080'


Vue.prototype.$vBACKEND_URL = 'https://api.hblwms.co.kr'
Vue.prototype.$vCONTENT_URL = 'https://api.hblwms.co.kr'

//-------------- LOCAL IP ------------------------------------
//Vue.prototype.$vBACKEND_URL = 'http://localhost:8080'
//Vue.prototype.$vCONTENT_URL = 'http://localhost:8080'
//-------------- HOSTING IP ------------------------------------
//Vue.prototype.$vBACKEND_URL = 'http://133.186.209.32:3001'
//Vue.prototype.$vCONTENT_URL = 'http://133.186.209.32:3001'

// Vue.prototype.$vBACKEND_URL = 'http://220.122.182.2:8085'
// Vue.prototype.$vCONTENT_URL = 'http://220.122.182.2:8085'

// Vue.prototype.$vBACKEND_URL = 'http://125.185.22.60:8085'
// Vue.prototype.$vCONTENT_URL = 'http://125.185.22.60:8085'


const store = new Vuex.Store({
  plugins: [
    createPersistedState()
  ],
  state: {
    uid_company:null,   // 사용자 회사 UID
    user_uid:-1,        // 사용자 UID
    user_id:null,       // 사용자 아이디
    user_name:null,     // 사용자명
    cp_code:null,       // 회사코드
    cp_name:null,       // 회사명
    accessToken:null,   // 접근토큰
    menuList:[],        // 메뉴 데이터
    codeType:null,       // 바코드 검색 코드 타입
    // codeFormat:0       // ##DBG 추가 2023001 
  },
  getters: {
    isLogin(state) {
      if(state.user_uid != null && state.user_uid > 1) {
        return true;
      } else {
        return false;
      }
    },
    getLoginDatas(state) {
      return state
    },
    getUidCompany(state) {
      return state.uid_company
    },
    getUserUid(state) {
      return state.user_uid
    },
    getUserId(state) {
      return state.user_id
    },
    getUserName(state) {
      return state.user_name
    },
    getCodeType(state) {
      return state.codeType
    },
    //##DBG 추가 20230327_1 : 
    //  getCodeFormat(state) {
    //    return state.codeFormat
    //  },
  },
  mutations: {
    setLoginDatas(state, obj) {
      state.uid_company = obj.uid_company;
      state.user_uid = obj.user_uid;
      state.user_id = obj.user_id;
      state.user_name = obj.user_name;
      state.cp_code = obj.cp_code;
      state.cp_name = obj.cp_name;
      state.codeType = obj.codeType
      // state.accessToken = obj.accessToken;
    },
    clearUserInfo(state) {
      // 회사코드 + 아이디 빼고 제거
      state.uid_company = null;
      state.user_uid = null;
      state.user_name = null;
      state.accessToken = null;
      state.menuList = [];
    },
    setCodeType(state, codeType) {
      state.codeType = codeType;
    },
    // //##DBG 추가 20230327_1 : 
    // setUidItem(state, uid_item) {
    //   state.uid_item =  uid_item;
    // },
  }
})

new Vue({
  vuetify,
  store,
  render: h => h(App),
  router
}).$mount('#app')
