// 라우트 객체에 사용할 링크
import VueRouter from'vue-router'

// 메뉴 Store
const store = [
  { code:'MAIN', name:'MAIN', link: 'components/views/Main' },
  { code:'LOGIN', name:'로그인', link: 'components/views/Login' },
  { code:'ITEM', name:'품목관리', link: 'components/views/ItemView' },
  { code:'STANDARD', name:'기준정보', link: 'components/views/StandardView' },
  { code:'WEARING', name:'입고', link: 'components/views/Wearing' },
  { code:'RELEASE', name:'출고', link: 'components/views/Release' },
  { code:'STOCKMOVE', name:'재고위치조정', link: 'components/views/StockMove' },
  { code:'STOCKMGMT', name:'재고현황', link: 'components/views/StockMgmt' },
  { code:'USERMGMT', name:'사용자관리', link: 'components/views/UserMgmt' },
  { code:'LOCATIONMAN', name:'위치관리', link: 'components/views/LocationMan'},
  { code:'INOUTLIST', name:'입출고내역', link: 'components/views/InOutList'},
  { code:'BINMAN', name:'창고검색', link: 'components/views/BinMan' },
]

const createRoutes = () => {
  let routes = [];

  if(store!=null && store.length>0) {
    for(var i in store) {
      var s = store[i];

      var code = s.code;
      var name = s.name;
      var link = s.link;
      
      var obj = {
        path: '/' + code.toLowerCase(),
        name: code,
        component: loadView(link),
        props: true,
        meta: { title:name }
      }

      routes.push(obj);
    }
  }

  // 항상 마지막에 입력.
  var def_route = {
    path:'/:catchAll(.*)',
    component: () => import('@/components/main/NotFoundPage.vue')
  }
  routes.push(def_route)

  return routes;
}

function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/${view}.vue`)
}

const router = new VueRouter({
  mode: "history",
  routes: createRoutes()
});




export default router;