<template>
  <v-app>

    <v-main v-if="!['LOGIN'].includes($route.name)">
      <Header />
      <div id="main">
        <LeftMenu />
        <div id="content">
          <router-view></router-view>
        </div>
      </div>
      <!-- <Footer /> -->
    </v-main>

    <v-main v-if="['LOGIN'].includes($route.name)">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import '@/assets/style/main.css';

import Header from './components/main/Header.vue'
import LeftMenu from './components/main/LeftMenu.vue'

export default {
  name: 'App',
  mounted() {           
    var is_login = this.$store.getters.isLogin;
    if(is_login) {
      try {
        this.getLastMenu()
      } catch (error) {
        this.$router.push('main').catch(()=>{});
      }
    } else {
      this.$router.push('login').catch(()=>{});
    }


  },
  watch: {
    $route(to, from) {
      const menu_code = to.name;
      this.updateLastMenu(menu_code)
    }
  },
  components: {
    Header,
    LeftMenu,
    // Footer
  },

  data: () => ({
    //
  }),

  methods: {
    updateLastMenu(menu_code) {
      if(menu_code==null || menu_code.length<1) return;
      if(menu_code=='MAIN') return;
      const userUid = this.$store.getters.getUserUid
      if(userUid==null || userUid<1) return;
      var url = this.$vBACKEND_URL + '/index/main.do?method=updateLastMenu';
      var obj = {
          uid_company:this.$store.getters.getUidCompany,
          user_uid:this.$store.getters.getUserUid,
          user_id:this.$store.getters.getUserId,
          user_name:this.$store.getters.getUserName,
          menu_code:menu_code
      }
      var params = '';
      for(var key in obj) {
          params += '&' + key + '=' + obj[key]
      }
      url += params;

      fetch(url)
      .then(res => {

      })
      .catch(err => {
          console.log(err)
      })
    },

    getLastMenu() {
      var url = this.$vBACKEND_URL + '/index/main.do?method=getLastMenu';
      var obj = {
          uid_company:this.$store.getters.getUidCompany,
          user_uid:this.$store.getters.getUserUid,
      }
      var params = '';
      for(var key in obj) {
          params += '&' + key + '=' + obj[key]
      }
      url += params;

      fetch(url)
        .then(res => res.clone().json())
        .then(res => {
            let result = res.datas
            if(result == null || result.length<1) result = 'main'
            try {
              this.$router.push(result).catch(()=>{});
            } catch (error) {
              console.log(' ');
            }
        })
        .catch(err => {
            console.log(err)
        })
    }
  }
};
</script>
